import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ShIntlMessageDefinition,
  getShMessage,
  useShTranslate,
  useShIntl,
} from './ShIntl';
import { ShTranslatable } from './ShTranslatable';

type Values = Record<string, any>;

export type ShTextProps<T extends string> = {
  message: T | ShIntlMessageDefinition<T>;
  values?: Values;
  children?: undefined;
  tagName?: React.ElementType;
};

//we add <br/> so translators can add {br} in backend (asked by Dara)
const DefaultTranslateValues = {
  // 3 different cases because translators often do typos (seen in practice)
  br: <br />,
  Br: <br />,
  BR: <br />,
};

const addDefaultTranslateValues = (values: Values | undefined): Values =>
  values
    ? {
        ...values,
        ...DefaultTranslateValues,
      }
    : DefaultTranslateValues;

export const ShText = <T extends string>({
  message,
  values,
  children,
  tagName = React.Fragment,
}: ShTextProps<T>) => {
  if (message) {
    const definition: ShIntlMessageDefinition<T> =
      typeof message === 'string' ? getShMessage(message) : message;

    return (
      <ShTranslatable id={definition.id}>
        <FormattedMessage
          {...definition}
          values={addDefaultTranslateValues(values)}
          tagName={React.Fragment}
        />
      </ShTranslatable>
    );
  } else {
    return React.createElement(tagName, undefined, children);
  }
};

export const ShTextHTML = ({
  message,
  values,
}: {
  message: ShIntlMessageDefinition;
  values?: Values;
}) => {
  const translate = useShTranslate();
  const translatedMessage = translate(message, values);

  return (
    <ShTranslatable id={message.id}>
      <div
        dangerouslySetInnerHTML={{
          __html: translatedMessage,
        }}
      />
    </ShTranslatable>
  );
};
