import React, { ReactNode } from 'react';
import { useShIntl } from './ShIntl';
import { useShIntlTranslateMode } from './ShIntl';

const ShTranslateButton = ({ id }: { id: string }) => {
  const { translateUrl, openTranslateInterface } = useShIntl();
  return (
    <a
      href={translateUrl(id)}
      target="_blank"
      rel="noopener"
      style={{ color: 'pink', display: 'inline !important' }}
      onClick={(e) => {
        if (openTranslateInterface) {
          e.preventDefault();
          openTranslateInterface(id);
        }
        e.stopPropagation();
      }}
    >
      *
    </a>
  );
};

// An element that can display a translation button if translation mode is enabled and an id is provided
// Only works for react-dom as it add a link to the children element
export const ShTranslatable = ({
  id,
  context,
  children,
}: {
  id: string;
  context?: string; // Give additional infos for the translator
  children?: ReactNode;
}) => {
  const isTranslateMode = useShIntlTranslateMode();
  if (isTranslateMode) {
    return (
      <>
        {children} {context} <ShTranslateButton id={id} />{' '}
      </>
    );
  } else {
    return <>{children}</>;
  }
};
