import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IntlContext, IntlProvider, ReactIntlError } from 'react-intl';
import { FormatError } from 'intl-messageformat';
import {
  ShLocale,
  ShLocaleConfigs,
  ShTranslationTarget,
} from '@shoootin/config';
import { ShTranslationApi } from '@shoootin/api';
import { AllKeys } from './ShTranslationKeys';

export type ShIntlErrorCallback = (err: ReactIntlError | FormatError) => void;

export type ShIntlMessageDefinition<T extends string = string> = {
  id: T;
  defaultMessage?: string;
  description?: string;
};

export type ShIntlMessages<T extends string = string> = { [key in T]: string };

// Just a fn to create message definitions in a potentially typesafe way
// Use this when you don't use defineMessages()
export const getShMessage = <T extends string>(
  key: T,
  defaultMessage?: string,
): ShIntlMessageDefinition<T> => {
  return { id: key, defaultMessage: defaultMessage ?? key };
};

// For dynamic keys, TS safety won't work
export const getShMessageUnsafe = (
  key: string,
  defaultMessage?: string,
): ShIntlMessageDefinition => getShMessage(key as AllKeys, defaultMessage);

// We need an extra context,
// because react-intl does not hold the locale,
// but only the language,
// and we need to be able to consume the language
type ShIntlContextValue = {
  messages: ShIntlMessages;
  setMessages: (messages: ShIntlMessages) => void;
  refreshMessages: () => Promise<void>;
  locale: ShLocale;
  translateMode: boolean;
  translateUrl: (id: string) => string;
  openTranslateInterface?: (id: string) => void;
};

// Do not expose this! we want to bake our own apis on top of react-intl ones
// And add some additional behavior on top, like reporting missing translations etc...
const useRawIntl = () => {
  const intl = useContext(IntlContext);
  if (!intl) {
    throw new Error('useIntl: no react-intl context found');
  }
  return useMemo(() => {
    return {
      isMessage: (key: string): boolean => !!intl.messages[key],

      translate: (
        ...[descriptor, values]: Parameters<typeof intl.formatMessage>
      ) => {
        // TODO generate warnings here if text is not translated etc...

        // Don't know why, but react-intl formatMessage use the defaultMessage over the actual message if the actual message contains html characters...
        // Workaround: replace the defaultMessage with the actualMessage
        // Example: the Shoootin order process has an help modal (id=enums_OrderHelpModalName_offerBasic)
        const fixedDescriptor: typeof descriptor = {
          ...descriptor,
          defaultMessage:
            (intl.messages[descriptor.id as string] as string) ??
            descriptor.defaultMessage,
        };

        // TODO is it always safe to cast result as string?
        return intl.formatMessage(fixedDescriptor, values) as string;
      },
      // Add other apis you want here... like number or currency or whatever
    };
  }, [intl]);
};

const ShIntlContext = React.createContext<ShIntlContextValue | null>(null);

const useShIntlContext = (): ShIntlContextValue => {
  const value = useContext(ShIntlContext);
  if (!value) {
    throw new Error('AppIntlContext was not provided');
  }
  return value;
};

// just a convenient alias
export const useShTranslate = () => useRawIntl().translate;

export const useShIntlLocale = () => useShIntlContext().locale;

export const useShIntlTranslateMode = () => useShIntlContext().translateMode;

export const useShIntlLanguage = (): string => {
  const locale = useShIntlLocale();
  return ShLocaleConfigs[locale].language;
};

// A merge of both contexts, can be useful api with all features available
export const useShIntl = () => {
  const shIntl = useShIntlContext();
  const rawIntl = useRawIntl();
  return useMemo(() => ({ ...shIntl, ...rawIntl }), [shIntl, rawIntl]);
};

const useShMessagesState = ({
  initialMessages,
  locale,
  target,
}: ShIntlProviderProps) => {
  const [messages, setMessages] = useState<ShIntlMessages>(initialMessages);
  useEffect(() => {
    __DEV__ && console.debug('ShIntlMessages', messages);
  }, [messages]);

  const refreshMessages = useCallback(async () => {
    const messages = await ShTranslationApi.getTranslations(locale, target);
    setMessages(messages);
  }, [locale, target]);

  const api = useMemo(
    () => ({
      setMessages,
      refreshMessages,
    }),
    [setMessages, refreshMessages],
  );

  return [messages, api] as const;
};

export type ShIntlProviderProps = {
  children?: ReactNode;
  initialMessages: ShIntlMessages;
  locale: ShLocale;
  target: ShTranslationTarget;
  translateMode?: boolean;
  translateUrl?: (id: string) => string;
  onError: ShIntlErrorCallback;
  translateInterface?: (params: {
    id: string;
    close: () => void;
    updateMessage: (message: string) => void;
  }) => ReactNode;
};

export const ShIntlProvider = (props: ShIntlProviderProps) => {
  const [messages, { setMessages, refreshMessages }] = useShMessagesState(
    props,
  );

  const [translateInterfaceId, setTranslateInterfaceId] = useState<string>();

  const openTranslateInterface = props.translateInterface
    ? (id: string) => setTranslateInterfaceId(id)
    : undefined;

  const translateUrl = useCallback(
    (id: string) => {
      return props.translateUrl
        ? props.translateUrl(id)
        : 'http://shoootin-translate-url-not-provided.com/?id=' + id;
    },
    [props.translateUrl],
  );

  // Ensure stable context value to avoid useless re-renders
  const contextValue: ShIntlContextValue = useMemo(
    () => ({
      messages,
      setMessages,
      refreshMessages,
      locale: props.locale,
      translateMode: props.translateMode ?? false,
      translateUrl,
      openTranslateInterface,
    }),
    [
      messages,
      refreshMessages,
      props.locale,
      props.translateMode,
      translateUrl,
    ],
  );

  return (
    <IntlProvider
      locale={ShLocaleConfigs[props.locale].language}
      messages={messages}
      onError={props.onError}
    >
      <ShIntlContext.Provider value={contextValue}>
        {props.children}

        {props.translateInterface &&
          translateInterfaceId &&
          props.translateInterface({
            id: translateInterfaceId,

            updateMessage: (message) => {
              // refreshMessages();

              console.log('updateMessage', { translateInterfaceId, message });
              setMessages((messages) => ({
                ...messages,
                [translateInterfaceId]: message,
              }));

              setTranslateInterfaceId(undefined);
            },
            close: () => setTranslateInterfaceId(undefined),
          })}
      </ShIntlContext.Provider>
    </IntlProvider>
  );
};
